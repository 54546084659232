// import axios from 'axios';
import { hanatourApi } from '../../main.js';
export default class ReportedUsersbycountsService {



    async getreportedUserscountList(obj) {
        var params = new URLSearchParams(obj).toString();
        return await hanatourApi.get(`admin/report/report-user-by-count?${params}`)
            .then((res) => res)
            .catch((err) => err)

    }


    async getreportedUsersbycountsdetail(obj) {
        // var params = new URLSearchParams(obj).toString();
        console.log(obj)
        // var id = localStorage.getItem('usercountid')
        // console.log(id)
        return await hanatourApi.get(`/admin/report/report-user-by-count-details/${obj.id}?record_count=${obj.record_count}&page=${obj.page}&reason_type=${obj.reason_type}&chatroom_name=${obj.chatroom_name}&group_type=${obj.group_type}&reported_by=${obj.reported_by}&create_start_date=${obj.create_start_date}&create_end_date=${obj.create_end_date}&is_secret=${obj.is_secret}`)

            .then((res) => res)
            .catch((err) => err)


    }

}